@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.fadingIn {
    animation: fadeIn 1s ease-in-out forwards;
}

body, html, #root, .App {
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
}

.modal-content::-webkit-scrollbar {
    width: 10px; /* Adjust width as per your requirement */
}

.modal-content::-webkit-scrollbar-track {
    background-color: black;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 2px; /* Optional: Rounds the corners */
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #007500; /* Optional: Change thumb color on hover */
}

.modal-content {
    scrollbar-width: thin;
    scrollbar-color: green black;
}